<template>
  <div>
    <v-simple-table fixed-header height="calc(100vh - 145px)" class="table-padding-2">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader">
              <InputFilter :label="$t('labels.id')" :placeholder="$t('labels.id')" name="id_identity"
                sort-name="id_identity" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th>
            <th role="columnheader">
              <InputFilter :label="$t('labels.id_employee')" :placeholder="$t('labels.id_employee')" name="id"
                sort-name="id" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
            </th>
            <th role="columnheader">
              <DateRangeFilter :label="$t('labels.created_date')" :placeholder="$t('labels.created_date')" name="created_at"
                sort-name="created_at" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th>
            <th role="columnheader">
              <InputFilter :label="$t('labels.name')" :placeholder="$t('labels.name')" name="full_name"
                sort-name="full_name" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th>
            <th role="columnheader">
              <InputFilter :label="$t('labels.account')" :placeholder="$t('labels.account')" name="email"
                sort-name="email" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
            </th>
            <th role="columnheader">
              <SelectPos :label="$t('labels.pos')" :placeholder="$t('labels.pos')" name="id_pos"
                sort-name="pos_code" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th>
            <th role="columnheader">
              <SelectFilter :options="departments" :label="$t('labels.department')" :placeholder="$t('labels.department')"
                name="id_department" sort-name="department" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th>
            <th role="columnheader">
              <SelectFilter :options="positions" :label="$t('labels.position')" :placeholder="$t('labels.position')"
                name="id_position" sort-name="position" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th>
            <th role="columnheader">
              <SelectFilter :options="statusOptions" :label="$t('labels.status')" :placeholder="$t('labels.status')"
                name="status" sort-name="status" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th>
            <th role="columnheader"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="employee in items" :key="employee.id" class="text-center">
            <td>{{ employee.id_identity }}</td>
            <td>{{ employee.id }}</td>
            <td>{{ formatDateTime(employee.created_at) }}</td>
            <td>
              <span class="cursor-pointer text-decoration-underline blue--text"
                v-if="checkPermission(['employee_update_info'])" @click="showDetailDialog(employee)">
                {{ employee.full_name }}</span>
              <span v-else>{{ employee.full_name }}</span>
            </td>
            <td>{{ employee.email }}</td>
            <td>{{ employee.employee_pos_codes }}</td>
            <td>{{ employee.department }}</td>
            <td>{{ employee.position }}</td>
            <td>
              <v-switch class="switch-center" v-model="employee.status" @change="switchEmployeeStatus(employee)"
                :key="`employee_${employee.id}_${employee.status}`"></v-switch>
            </td>
            <td>
              <v-btn color="warning" v-if="checkPermission(['super_admin'])" :disabled="!employee.status" outlined small
                class="ma-1" @click="loginWith(employee)">Login</v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" sm="3">
        <div class="d-flex table-footer-count mt-3">
          <div class="mr-2">{{ $t('labels.employee') }}: <b>{{ formatNumber(totalItem) }}</b></div>
          <div class="mr-2">Active: <b>{{ formatNumber(totalActive) }}</b></div>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <v-pagination v-model="page" :length="totalPage" :total-visible="6"></v-pagination>
      </v-col>
      <v-col cols="12" sm="3"></v-col>
    </v-row>

    <v-dialog v-model="detailDialog" persistent max-width="1024px">
      <DialogDetail v-if="detailDialog && employee && employee.id" :employee="employee" :departments="departments"
        :positions="positions" @closeDialog="hideDetailDialog" @refreshData="getList" />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import Cookies from "js-cookie";
import { debounce } from "lodash/function";

export default {
  components: {
    SelectPos: () => import('@/components/table/SelectPos'),
    DateRangeFilter: () => import('@/components/table/DateRangeFilter'),
    SelectFilter: () => import('@/components/table/SelectFilter'),
    InputFilter: () => import('@/components/table/InputFilter'),
    DialogDetail: () => import('@/components/employee/DialogDetail'),
  },
  name: "Index",
  data: () => ({
    page: 1,
    totalPage: 1,
    totalItem: 0,
    totalActive: 0,
    items: [],
    filters: {
      sort_by: 'id',
      sort_type: 'desc'
    },
    statusOptions: [
      {
        text: 'Active',
        value: 10
      },
      {
        text: 'Inactive',
        value: 0
      },
    ],
    employee: {},
    detailDialog: false,
    isLoading: false,
    departments: [],
    positions: [],
  }),
  watch: {
    page() {
      this.getList()
    },
    filters: {
      handler() {
        this.page = 1
        this.getList()
      },
      deep: true
    }
  },
  methods: {
    getList: debounce(function () {
      httpClient.post('/employee-list', { ...this.filters, page: this.page }).then(({ data }) => {
        this.totalPage = data.totalPage
        this.totalItem = data.total
        this.totalActive = data.total_active
        this.items = [...data.rows].map(r => {
          r.status = r.identity_status === 10
          return r
        })
      })
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value }
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort }
    },
    switchEmployeeStatus(employee) {
      if (!confirm(`Bạn có chắc chắn muốn cập nhật trạng thái ${employee.full_name}`)) {
        this.getList()
        return false
      }
      httpClient.post('/employee-update-status', {
        identity_id: employee.id_identity,
        status: (employee.status ? 10 : 0)
      }).then(({ data }) => {
        console.log('switchEmployeeStatus', data)
      })
    },
    showDetailDialog(employee) {
      this.employee = { ...employee }
      this.detailDialog = true
    },
    hideDetailDialog() {
      this.employee = {}
      this.detailDialog = false
    },
    async getDepartmentAndPosition() {
      const { data } = await httpClient.post('/department-position')
      this.departments = [...data.departments].map(e => ({
        value: e.id,
        text: e.department
      }))
      this.positions = [...data.positions].map(e => ({
        value: e.id,
        text: e.position
      }))
    },
    async loginWith(employee) {
      if (!confirm(`${this.$t('labels.login_with')}: ${employee.full_name}`)) {
        return false
      }

      if (this.isLoading) {
        this.isLoading = false
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true

      try {
        const { data } = await httpClient.post('/employee-login-with', {
          employee
        })
        this.isLoading = false

        const st = Cookies.get('_smt')
        Cookies.set('_smto', st, { expires: 365 })
        Cookies.set('_smt', data.token, { expires: 365 })

        window.location = process.env.VUE_APP_V2_HOME_PATH
      } catch (e) {
        this.isLoading = false
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg);
      }
    }
  },
  mounted() {
    this.getList()
    this.getDepartmentAndPosition()
  }
}
</script>

<style scoped></style>
